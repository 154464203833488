<template>
  <v-card
    v-bind="$attrs"
    class="diamond-card elevation-4 transparent"
    :class="{
      'section-hidden': isHidden,
      'no-border': noBorder || noBorder === '',
    }"
  >
    <ul
      class="diamond-card-buttons-wrapper"
      :class="direction + ' ' + (isHidden ? 'hidden' : '')"
    >
      <li
        class="direction-wrapper diamond-card-icon-wrapper"
        :class="{
          right: direction === 'right',
          left: direction === 'left',
          editable,
          open: isEdit,
          'first-red': firstRed,
        }"
        v-if="editable"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="diamond-card-icon direction-icon"
              color="secondary"
              icon
              :disabled="globalBlock"
              @click="edit()"
            >
              <v-icon size="18" color="white">icon-tools-2</v-icon>
            </v-btn>
          </template>
          <span>EDIT</span>
        </v-tooltip>
      </li>

      <li
        v-for="button in buttons"
        :key="button.action"
        class="direction-wrapper diamond-card-icon-wrapper"
        :class="{
          hidden: !isEdit && editable,
          right: direction === 'right',
          left: direction === 'left',
          editable,
          'first-red': firstRed,
        }"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :disabled="button.disabled || globalBlock"
              :loading="button.loading"
              class="diamond-card-icon direction-icon"
              @click="onAction(button)"
              icon
            >
              <v-icon size="18" color="white">{{ button.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ button.name }}</span>
        </v-tooltip>
      </li>
    </ul>
    <v-card-text class="diamond-card-text pa-0" :style="cardStyle">
      <div
        class="diamond-card-content"
        :class="{
          'bg-grey-gradient': $attrs.dark || $attrs.dark === '',
        }"
        :style="colStyle"
      >
        <slot
          v-bind:loading="loading"
          v-bind:attrs="{ id }"
          name="content"
        ></slot>
      </div>
    </v-card-text>
  </v-card>
</template>


<script>
import { DEFAULT_CARD_BUTTONS } from "../../../constants/buttons/card.buttons";
import { v4 as uuidv4 } from "uuid";
import { CommonHelper } from "../../../helpers/common.helper";

export default {
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    collapseOnEdit: {
      type: Boolean,
      default: false,
    },

    // other
    direction: {
      type: String,
      default: "left",
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    firstRed: {
      type: Boolean,
      default: false,
    },

    buttons: {
      type: Array,
      default: () => [
        DEFAULT_CARD_BUTTONS.ACTION.copy(),
        DEFAULT_CARD_BUTTONS.CANCEL.copy(),
      ],
    },
  },
  data() {
    return {
      id: uuidv4(),
      loading: false,
      collapsed: false,
      isEdit: false,
      colStyle: {},
      cardStyle: {},
      globalBlock: false,
      blockTimeout: undefined,
    };
  },
  mounted() {
    // const { height } = document.getElementById(this.id).getBoundingClientRect();
    // this.onAction();
    if (this.collapseOnEdit) {
      this.collapsed = false;
      this.colStyle = {
        position: "absolute",
        "z-index": 7,
        " -webkit-transition:": "",
        // transform: `translate(0, -${height}px)`,
        transform: `translate(0, -100%)`,
      };

      this.cardStyle = {
        height: "1px",
        position: "absolute",
      };
    }
    // this.collapse(false);
  },
  methods: {
    edit() {
      this.setGlobalBlock();

      this.isEdit = true;

      if (this.collapseOnEdit) this.collapse(true);
    },
    onAction(button) {
      this.setGlobalBlock();
      if (this.collapseOnEdit && button.autoCollapse) this.collapse(false);

      this.$emit(button.action, this);
    },
    setGlobalBlock() {
      if (this.blockTimeout) clearTimeout(this.blockTimeout);
      this.globalBlock = true;
      this.blockTimeout = setTimeout(() => {
        this.globalBlock = false;
      }, 500);
    },
    async collapse(val) {
      if (!val) this.isEdit = false;

      // const { height } = document
      //   .getElementById(this.id)
      //   .getBoundingClientRect();

      // console.log("height: ", height);

      if (val) {
        this.cardStyle = {
          // height: `${height}px`,
          height: `100%`,
        };
        this.colStyle = {
          // transform: `translate(0, 1px)`,
          transform: `translate(0, 0%)`,
        };
      } else {
        this.cardStyle = {
          // height: `${height}px`,
          height: `1px`,
          // position: "absolute",
        };
        this.colStyle = {
          // position: "absolute",
          "z-index": 7,
          // transform: `translate(0, -${height}px)`,
          transform: `translate(0, -100%)`,
        };
        await CommonHelper.delay(400);
        this.cardStyle = {
          height: "1px",
          position: "absolute",
        };
      }

      this.collapsed = val;
    },
  },
};
</script>


<style lang="scss" scoped>
.diamond-card {
  position: relative;
  width: 100%;
  border-color: #1e1e1e;
  border-top: 1px solid !important;
  border-radius: 0px;
  overflow: visible;
  &.dark {
    border-color: #f8f8f8;
  }

  &.no-border {
    border: 0px !important;
  }

  .diamond-card-text {
    overflow: hidden;
    -webkit-transition: -webkit-transform 0.3s linear;

    .diamond-card-content {
      width: 100%;

      -webkit-transition: -webkit-transform 0.3s linear;
    }
  }
  // transition: all linear;
  -webkit-transition: -webkit-transform 0.3s linear;

  // -ms-transition: height 0.3s;
  // -o-transition: height 0.3s;
  // -webkit-transition: height 0.3s;
  // transition: height 0.3s;

  .diamond-card-buttons-wrapper {
    position: absolute;
    z-index: 9;
    top: 0px;
    left: 0px;
    display: flex;
    width: 100%;
    height: 2px;
    transition: all 0.4s;
    z-index: 2;
    padding-left: 0px !important;

    font-weight: 300;
    font-size: 14px;
    line-height: 36px;

    height: 1px;
    background-color: black;
    opacity: 1;

    &.right {
      left: auto;
      right: 0;
      flex-direction: row-reverse;
      margin-left: 0px;
    }

    &.hidden {
      opacity: 0;

      background-color: transparent;
      transform: translate(-100%, 0);
    }

    .diamond-card-icon-wrapper {
      padding: 10px;
      position: relative;
      // margin-top: 14px;
      transition: all 0.4s;
      transform-style: preserve-3d;
      border: 1px solid black;
      z-index: 9;

      margin-top: -16px;
      margin-left: 20px;

      width: 32px;
      height: 32px;

      &.right {
        margin-left: 0px;
        margin-right: 20px;
      }

      .theme--light.v-icon:focus::after {
        opacity: 0 !important;
      }

      .diamond-card-icon {
        font-size: 20px;
        font-weight: 300;
      }
      &:hover {
        //    transform: rotateY(20deg);

        // background-color: rgba(0,0,0,0.8);
        border: 1px solid white !important;
      }
      &.hidden {
        opacity: 0;
        z-index: -1;
      }
    }

    .diamond-card-icon-wrapper:first-child {
      background-color: white;
      z-index: 10;
      &.editable {
        position: absolute;
      }
      &.open {
        display: none;
      }
    }

    .diamond-card-icon-wrapper:nth-child(2) {
      margin-left: 20px;

      &.right {
        margin-left: 0;
        margin-right: 20px;
      }

      &.editable {
        &.right {
          margin-left: 0;
          margin-right: 20px;
        }
      }
    }

    .diamond-card-icon-wrapper:nth-child(n + 3) {
      margin-left: 20px;
      &.right {
        margin-left: 0;
        margin-right: 20px;
      }
    }

    $n: 10;
    @for $i from 2 through $n {
      .diamond-card-icon-wrapper:nth-child(#{$i}) {
        margin-left: 20px;

        &.hidden {
          transform: translate(-52px * $i + 52px, 0) rotate(45deg);
          &.editable {
            transform: translate(-52px * $i + 104px, 0) rotate(45deg);
          }

          &.right {
            transform: translate(52px * $i - 52px, 0) rotate(45deg);
            &.editable {
              transform: translate(52px * $i - 104px, 0) rotate(45deg);
            }
          }
        }
      }
    }

    .diamond-card-icon-wrapper:nth-child(odd) {
      background: linear-gradient(to right, #010c15, #1f2830);
      border: 1px solid black;

      &.first-red {
        background: linear-gradient(to right, #cd303b, #fc4a03);
        border: 1px solid #cd303b;
      }
    }

    .diamond-card-icon-wrapper:nth-child(even) {
      background: linear-gradient(to right, #cd303b, #fc4a03);
      border: 1px solid #cd303b;
      &.first-red {
        background: linear-gradient(to right, #010c15, #1f2830);
        border: 1px solid black;
      }
    }

    .diamond-card-icon-wrapper::after {
      background-color: transparent !important;
    }
  }
}
</style>