export class GeneralButton {


    constructor(btn) {
        this.__btn = btn
    }

    copy() {
        return Object.assign({}, this.__btn);
    }
    toJSON() {
        return this.__btn;

    }
}