import {
    GeneralButton
} from "../../common/RightButton"


export const DEFAULT_CARD_BUTTONS = {
    ACTION: new GeneralButton({
        name: "Save",
        action: "onAction",
        icon: 'mdi-check',
        autoCollapse: false
    }),
    CANCEL: new GeneralButton({
        name: "Cancel",
        action: "onCancel",
        icon: "mdi-close",
        autoCollapse: true
    }),
    REMOVE: new GeneralButton({
        name: "Remove",
        action: "onRemove",
        icon: "mdi-trash-can-outline",
        autoCollapse: true
    })
}