<template>
  <div class="slider-wrapper">
    <v-slide-group
      v-bind="$attrs"
      v-model="activeStep"
      class="pt-6 pb-6 pl-0 pr-0 form-slider"
      mandatory
      show-arrows
      center-active
    >
      <v-card
        color="transparent"
        class="ma-2 pl-1 pr-1 elevation-0"
        height="40"
        width="40"
      >
        <v-row class="fill-height" align="center" justify="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="addDisabled"
                icon
                dark
                v-bind="attrs"
                v-on="on"
                color="primary"
                @click="addStep"
              >
                <v-icon size="38" v-text="'mdi-plus'"></v-icon>
              </v-btn>
            </template>
            <span>{{ addText }}</span>
          </v-tooltip>
        </v-row>
      </v-card>
      <v-slide-item
        v-for="(item, i) in value"
        :key="i"
        v-slot="{ active, toggle }"
      >
        <v-card
          :color="active ? 'primary' : ''"
          class="ma-2 pl-1 pr-1 elevation-0 selector-card"
          height="40"
          width="100"
          @click="toggle"
        >
          <v-row class="fill-height" align="center" justify="center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="form-slider-header" v-bind="attrs" v-on="on">{{
                  index ? i + 1 : getName(item)
                }}</span>
              </template>
              <span>{{ getName(item) }}</span>
            </v-tooltip>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <DiamondCardVue
      class="elevation-0"
      cancel
      firstRed
      :direction="'right'"
      :buttons="dButtons"
      @onRemove="onRemove"
      v-if="value && value.length"
    >
      <template v-slot:content="{ attrs }">
        <slot
          name="form"
          v-bind:attrs="attrs"
          v-bind:item="value[activeStep] || {}"
          v-bind:index="activeStep"
        ></slot>
      </template>
    </DiamondCardVue>
  </div>
</template>


<script>
import { DEFAULT_CARD_BUTTONS } from "../../../constants/buttons/card.buttons";
import DiamondCardVue from "./DiamondCard.vue";
import _ from "lodash";

export default {
  props: {
    value: {},
    itemName: {
      type: String,
      default: "name",
    },
    itemID: {
      type: String,
      default: "id",
    },
    addText: {
      type: String,
      default: "Add",
    },
    addDisabled: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DiamondCardVue,
  },
  data() {
    return {
      activeStep: this.value && this.value[0] ? this.value[0] : 0,
      dButtons: [DEFAULT_CARD_BUTTONS.REMOVE.copy()],
    };
  },
  methods: {
    addStep() {
      this.$emit("onAdd");
    },
    onRemove() {
      this.$emit("onRemove", this.value[this.activeStep]);
    },
    getName(item) {
      try {
        return _.get(item, this.itemName);
      } catch (error) {
        return "";
      }
      //   @input="$emit('input', $event)"
    },
    getId(item) {
      try {
        return item[this.itemID];
      } catch (error) {
        return "";
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.slider-wrapper {
  width: 100%;
}
.form-slider {
  .form-slider-header {
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .selector-card {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
  }
}
</style>