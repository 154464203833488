var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',_vm._b({staticClass:"diamond-card elevation-4 transparent",class:{
    'section-hidden': _vm.isHidden,
    'no-border': _vm.noBorder || _vm.noBorder === '',
  }},'v-card',_vm.$attrs,false),[_c('ul',{staticClass:"diamond-card-buttons-wrapper",class:_vm.direction + ' ' + (_vm.isHidden ? 'hidden' : '')},[(_vm.editable)?_c('li',{staticClass:"direction-wrapper diamond-card-icon-wrapper",class:{
        right: _vm.direction === 'right',
        left: _vm.direction === 'left',
        editable: _vm.editable,
        open: _vm.isEdit,
        'first-red': _vm.firstRed,
      }},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"diamond-card-icon direction-icon",attrs:{"color":"secondary","icon":"","disabled":_vm.globalBlock},on:{"click":function($event){return _vm.edit()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"white"}},[_vm._v("icon-tools-2")])],1)]}}],null,false,420278052)},[_c('span',[_vm._v("EDIT")])])],1):_vm._e(),_vm._l((_vm.buttons),function(button){return _c('li',{key:button.action,staticClass:"direction-wrapper diamond-card-icon-wrapper",class:{
        hidden: !_vm.isEdit && _vm.editable,
        right: _vm.direction === 'right',
        left: _vm.direction === 'left',
        editable: _vm.editable,
        'first-red': _vm.firstRed,
      }},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"diamond-card-icon direction-icon",attrs:{"disabled":button.disabled || _vm.globalBlock,"loading":button.loading,"icon":""},on:{"click":function($event){return _vm.onAction(button)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"white"}},[_vm._v(_vm._s(button.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(button.name))])])],1)})],2),_c('v-card-text',{staticClass:"diamond-card-text pa-0",style:(_vm.cardStyle)},[_c('div',{staticClass:"diamond-card-content",class:{
        'bg-grey-gradient': _vm.$attrs.dark || _vm.$attrs.dark === '',
      },style:(_vm.colStyle)},[_vm._t("content",null,{"loading":_vm.loading,"attrs":{ id: _vm.id }})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }